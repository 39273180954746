import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFreelancers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}freelancers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFreelancer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}freelancers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFreelancer(ctx, freelancer) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}freelancers`, freelancer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgreements(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}agreements?freelancerID=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAgreement(ctx, agreement) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}agreements`, agreement)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgreement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}agreements/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoices(ctx, data, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}invoices?freelancerID=${data.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFreelancer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}freelancers/${data.id}/link`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveInvoice(ctx, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${invoice.id}`, invoice)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendInviteFreelancer(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}freelancers/${id}/mailinvite`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayPeriods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}paychecks/periods`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
