<template>
  <div>
    <b-modal
      id="modal-disapprove-invoice"
      ref="modalDisapproveInvoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Factuur afwijzen"
      :hide-footer="true"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refDisapproveInvoiceObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handelDisapproveInvoice)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="denyReason"
                rules="required"
              >
                <b-form-group>
                  <label>Reden</label>
                  <b-form-textarea
                    id="denyReason"
                    v-model="disapproveInvoiceData.denyReason"
                    rows="2"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showInvoiceDisapprovalSpinner"
                  small
                  class="mr-1"
                />
                Afwijzen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer/>
    </b-modal>
    <b-modal
      id="modal-change-paycheck-period-invoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Verloningsperiode wijzigen"
      ref="modalChangePaycheckPeriodInvoice"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleChangePaycheckPeriode)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Verloningsperiode"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <label >Selecteer een verloningsperiode</label>
                  <v-select
                    id="paycheckPeriodSelected"
                    v-model="paycheckPeriodSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paycheckPeriod"
                    :reduce="val => val.key"
                    label="value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  small
                  class="mr-1"
                  v-if="showChangePaycheckPeriodSpinner"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-agreement"
      ref="modalAgreement"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
      lazy
    >
      <form-wizard
        v-if="showAdd"
        ref="createAgreementWizard"
        :start-index="0"
        color="#29625b"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Aanmaken"
        back-button-text="Terug"
        next-button-text="Volgende"
        class="mb-3"
        @on-complete="agreementFormSubmitted"
      >
        <tab-content
          title=""
          :before-change="validationAgreementStep1Form"
        >
          <validation-observer
            ref="agreementStep1"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Modelovereenkomst
                </h5>
                <small class="text">
                  We werken met een modelovereenkomst, zodat we voor zowel de opdrachtgever als opdrachtnemer de gemaakte afspraken goed vastleggen. Een aantal zaken kan je zelf, naar eigen inzicht aanpassen, zoals tarief, aanvullende afspraken, aansprakelijkheid etc.
                </small>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Overeenkomst naam"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="agreement.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="sectorID"
                  rules="required"
                >
                  <b-form-group
                    label="Sector"
                    label-for="sectorID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="sectorID"
                      v-model="agreement.sectorID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sectorOptions"
                      :reduce="val => val.value"
                      label="name"
                      :clearable="false"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="startDate"
                  rules="required|date"
                >
                  <b-form-group
                    label="Startdatum"
                    label-for="startDate"
                    :state="errors.length > 0 ? false:null"
                  >
                    <flat-pickr
                      id="startDate"
                      v-model="agreement.startDate"
                      :config="startDateConfig"
                      :class="errors.length > 0 ? 'error':''"
                    >
                    </flat-pickr>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="endDate"
                  :rules="agreement.isUndeterminedDuration?'':'required|date'"
                >
                  <b-form-group
                    label="Einddatum"
                    label-for="endDate"
                    :state="errors.length > 0 ? false:null"
                  >
                    <flat-pickr
                      id="endDate"
                      v-model="agreement.endDate"
                      :config="endDateConfig"
                      :class="errors.length > 0 ? 'error':''"
                      :disabled="agreement.isUndeterminedDuration"
                    >
                    </flat-pickr>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <b-form-checkbox
                    name="isUndeterminedDuration"
                    v-model="agreement.isUndeterminedDuration"
                    @change="isUndeterminedDurationChecked($event)"
                  >
                    Onbepaalde looptijd
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title=""
          :before-change="validationAgreementStep2Form"
        >
          <validation-observer
            ref="agreementStep2"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Omschrijving
                </h5>
                <small class="text">
                  Vul hier duidelijk in wat je precies gaat doen bij je opdrachtgever. Indien je meerdere zaken tegelijk wil factureren met een andere omschrijving, voeg dan een regel toe.
                </small>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in agreement.activities"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <!-- Item Name -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="`itemDescription${index}`"
                  rules="required"
                >
                  <b-form-group
                    label="Omschrijving"
                    :label-for="`itemDescription${index}`"
                  >
                    <b-form-input
                      :id="`itemDescription${index}`"
                      v-model="item.description"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Cost -->
              <b-col md="3">
                <validation-provider
                  #default="validationContext"
                  :name="`itemAmount${index}`"
                  rules="required"
                >
                  <b-form-group
                    label="tarief"
                    :label-for="`itemAmount${index}`"
                  >
                    <b-form-input
                      :id="`itemAmount${index}`"
                      v-model="item.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <validation-provider
                  #default="validationContext"
                  :name="`itemUnit${index}`"
                  rules="required"
                >
                  <b-form-group
                    label="Eenheid"
                    :label-for="`itemUnit${index}`"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      :id="`itemUnit${index}`"
                      v-model="item.unit"
                      type="text"
                      :state="getValidationState(validationContext)"
                      placeholder="Uur, Dienst, Bezorging"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="2"
                class="mb-50"
              >
                <b-button
                  v-if="index > 0"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Regel toevoegen</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title=""
          :before-change="validationAgreementStep3Form"
        >
          <validation-observer
            ref="agreementStep3"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Aanvullende afspraken & betaaltermijn
                </h5>
                <small class="text">
                  Hier kan je eventueel aanvullende zaken vastleggen die van invloed zijn op de samenwerking, wellicht dat je opdrachtgever graag afspraken wil maken over aansprakelijkheid, relatie/concurrentiebedingen of dat je werknemersverzekeringen afdraagt.
                </small>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Betaaltermijn"
                  label-for="paymentTerm"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="paymentTerm"
                    rules="required|integer|between:7,60"
                  >
                    <b-form-input
                      id="paymentTerm"
                      v-model="agreement.paymentTerm"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Aansprakelijkheid bedrag"
                  label-for="paymentTerm"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="liabilityAmount"
                    rules="required|integer|between:0,5000"
                  >
                    <b-form-input
                      id="liabilityAmount"
                      v-model="agreement.liabilityAmount"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasRelationshipClause"
                  rules=""
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      name="hasRelationshipClause"
                      v-model="agreement.hasRelationshipClause"
                      :state="getValidationState(validationContext)"
                    >
                      Relatiebeding
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="agreement.hasRelationshipClause"
                cols="12"
              >
                <b-form-group
                  label="Relatiebeding termijn in maanden"
                  label-for="relationshipClauseDays"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="relationshipClauseDays"
                    rules="required|integer|between:1,24"
                  >
                    <b-form-input
                      id="relationshipClauseDays"
                      v-model="agreement.relationshipClauseDays"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="agreement.hasRelationshipClause"
                cols="12"
              >
                <b-form-group
                  label="Overtredingsboete per overtreding (Min: 0 & Max: 25000)"
                  label-for="relationshipClauseOffenceFine"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="relationshipClauseOffenceFine"
                    rules="required|integer|between:0,25000"
                  >
                    <b-form-input
                      id="relationshipClauseOffenceFine"
                      v-model="agreement.relationshipClauseOffenceFine"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="agreement.hasRelationshipClause"
                cols="12"
              >
                <b-form-group
                  label="Overtredingsboete per dag (Min: 0 & Max: 2500)"
                  label-for="relationshipClauseDayFine"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="relationshipClauseDayFine"
                    rules="required|integer|between:0,2500"
                  >
                    <b-form-input
                      id="relationshipClauseDayFine"
                      v-model="agreement.relationshipClauseDayFine"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasSecrecy"
                  rules=""
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      name="hasSecrecy"
                      v-model="agreement.hasSecrecy"
                      :state="getValidationState(validationContext)"
                    >
                      Geheimhouding
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="agreement.hasSecrecy"
                cols="12"
              >
                <b-form-group
                  label="Geheimhoudingsbeding boete (Min: 0 & Max: 25000)"
                  label-for="secrecyFine"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="secrecyFine"
                    rules="required|integer|between:0,25000"
                  >
                    <b-form-input
                      id="secrecyFine"
                      v-model="agreement.secrecyFine"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasSocialChargeNote"
                  rules=""
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      name="hasSocialChargeNote"
                      v-model="agreement.hasSocialChargeNote"
                      :state="getValidationState(validationContext)"
                    >
                      Afdracht werknemersverzekeringen
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title=""
          :before-change="validationAgreementStep4Form"
        >
          <validation-observer
            ref="agreementStep4"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  De overeenkomst beëindiging
                </h5>
                <small class="text">
                  Hier kan je afspraken maken inzake de beindiging van de overeenkomst, dit kan door zowel de opdrachtgever als de opdrachtnemer gedaan worden.  LET OP: je werkt voor eigen rekening en ontslagbescherming is niet van toepassing.
                </small>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="noticePeriod"
                  rules="required"
                >
                  <b-form-group
                    label="Opzegtermijn"
                    label-for="noticePeriod"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="agreement.noticePeriod"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="noticePeriods"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="hasCancellationCompensation"
                  rules=""
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-checkbox
                      name="hasCancellationCompensation"
                      v-model="agreement.hasCancellationCompensation"
                      :state="getValidationState(validationContext)"
                    >
                      Opzegvergoeding
                    </b-form-checkbox>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="agreement.hasCancellationCompensation"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Freelancer percentage"
                  label-for="cancellationCompensationAmountFreelancer"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cancellationCompensationAmountFreelancer"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="cancellationCompensationAmountFreelancer"
                      v-model="agreement.cancellationCompensationAmountFreelancer"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="agreement.hasCancellationCompensation"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Opdrachtgever percentage"
                  label-for="cancellationCompensationAmountCompany"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cancellationCompensationAmountCompany"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="cancellationCompensationAmountCompany"
                      v-model="agreement.cancellationCompensationAmountCompany"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-modal>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card
            title="Gegevens"
          >
            <!-- User Info: Input Fields -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(updateFreelancer)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Voornaam"
                      rules=""
                    >
                      <b-form-group
                        label="Voornaam"
                        label-for="firstName"
                      >
                        <b-form-input
                          id="firstName"
                          v-model="freelancerData.firstName"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="middleName"
                      rules=""
                    >
                      <b-form-group
                        label="Tussenvoegsel"
                        label-for="middleName"
                      >
                        <b-form-input
                          id="middleName"
                          v-model="freelancerData.middleName"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Achternaam"
                      rules=""
                    >
                      <b-form-group
                        label="Achternaam"
                        label-for="lastName"
                      >
                        <b-form-input
                          id="lastName"
                          v-model="freelancerData.lastName"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="E-mailadres"
                      rules=""
                    >
                      <b-form-group
                        label="E-mailadres"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="freelancerData.email"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Telefoon"
                      rules=""
                    >
                      <b-form-group
                        label="Telefoon"
                        label-for="phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="freelancerData.phone"
                          :state="getValidationState(validationContext)"
                          disabled="disabled"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="externalIdentifier"
                      rules=""
                    >
                      <b-form-group
                        label="Kenmerk"
                        label-for="externalIdentifier"
                      >
                        <b-form-input
                          id="externalIdentifier"
                          v-model="freelancerData.link.externalIdentifier"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        name="blockFreelancerInvoiceCreate"
                        v-model="freelancerData.link.blockFreelancerInvoiceCreate"
                        :value="1"
                        :unchecked-value="0"
                      >
                        Freelancer mag niet zelf facturen aanmaken
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        name="companyPaysFee"
                        id="companyPaysFee"
                        v-model="freelancerData.link.companyPaysFee"
                        :value="1"
                        :unchecked-value="0"
                      >
                        Bedrijf betaald de handeling fee
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="showPercentage"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="companyPaysPercentage"
                      rules="required|integer|between:1,100"
                    >
                      <b-form-group
                        label="Percentage (%)"
                        label-for="companyPaysPercentage"
                      >
                        <b-form-input
                          id="companyPaysPercentage"
                          v-model="freelancerData.link.companyPaysPercentage"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  class="mt-2"
                >
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showAgreementOverlay"
          rounded="sm"
        >
          <b-card
            title="Overeenkomsten"
          >
            <b-row class="mt-0 mb-2">
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-end"
              >
                <b-button
                  variant="secondary"
                  @click="showAddModal"
                >
                  <span class="text-nowrap">Nieuwe overeenkomst aanmaken</span>
                </b-button>
              </b-col>
            </b-row>
            <b-table
              :items="agreements"
              :fields="tableAgreementsColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomst(en) gevonden"
              class="position-relative"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>
              <template #cell(status)="data">
                {{resolveTypeAgreementVariant(data.value).status}}
              </template>
              <template #cell(freelancerConfirmTimestamp)="data">
                {{ data.value?formatDateTime(data.value):'In afwachting' }}
              </template>
              <template #cell(startDate)="data">
                {{ formatDate(data.value) }}
              </template>
              <template #cell(endDate)="data">
                {{ data.value?formatDate(data.value):'Onbepaalde tijd' }}
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewAgreement(data.item)"
                  />
                  <b-link
                    :href="`${axiosDefaultConfig.backendEndPoint}agreements/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                  </b-link>
                  <feather-icon
                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"
                    :id="`agreement-row-${data.item.id}-approval-icon`"
                    class="cursor-pointer ml-1"
                    icon="CheckCircleIcon"
                    size="16"
                    style="color:rgb(40,199,111);"
                    @click="confirmAgreementApproveText(data.item.id)"
                  />
                  <feather-icon
                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"
                    :id="`agreement-row-${data.item.id}-disapprive-icon`"
                    class="cursor-pointer ml-1"
                    icon="XCircleIcon"
                    size="16"
                    style="color:rgb(234,84,85) !important;"
                    @click="confirmAgreementDisapproveText(data.item.id)"
                  />
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showPaySlipsOverlay"
          rounded="sm"
        >
          <b-card
            title="Facturen"
          >
            <b-table
              :items="invoices"
              :fields="tableInvoicesColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomende records gevonden"
              class="position-relative mb-4"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(status)="data">
                <b-badge
                  :id="`invoice-row-${data.item.id}-status-error`"
                  :variant="resolveTypeVariant(data.value).variant"
                >
                  {{ resolveTypeVariant(data.value).status }} {{ data.item.hasCollection && userData.type === 2 ?` - Verzamelfactuur: ${data.item.collectionID}`:'' }}
                </b-badge>
                <b-tooltip
                  v-if="data.item.status === 50 || data.item.status === 51"
                  :target="`invoice-row-${data.item.id}-status-error`"
                  placement="left"
                >
                  <p
                    v-if="data.item.status === 50"
                    class="mb-0"
                  >
                    Afgekeurd door freelancer: {{ data.item.denyReasonFreelancer ? data.item.denyReasonFreelancer : 'Zonder opgaaf van reden' }}
                  </p>
                  <p
                    v-if="data.item.status === 51"
                    class="mb-0"
                  >
                    Afgekeurd door organisatie: {{ data.item.denyReasonCompany ? data.item.denyReasonCompany : 'Zonder opgaaf van reden' }}
                  </p>
                  <p
                    v-if="data.item.status === 52"
                    class="mb-0"
                  >
                    Afgekeurd door Payrite: {{ data.item.denyReasonAdmin ? data.item.denyReasonAdmin : 'Zonder opgaaf van reden' }}
                  </p>
                </b-tooltip>
              </template>

              <template #cell(companyID)="data">
                {{ data.item.company.name }}
              </template>

              <template #cell(createTimestamp)="data">
                {{ formatDateTime(data.value) }}
              </template>

              <template #cell(totalAmount)="data">
                &euro; {{parseFloat(data.value).toFixed(2)}}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :id="`approve-row-${data.item.id}-invoice`"
                    v-if="data.item.status === 11 && userData.type === 1 || userData.type === 2 && data.item.isCompanyApproved === 0"
                    @click="confirmApproveInvoice(data.item.id)"
                  >
                    <feather-icon
                      :id="`approve-row-${data.item.id}-invoice-icon`"
                      class="cursor-pointer"
                      icon="CheckCircleIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Accepteren
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`decline-row-${data.item.id}-invoice`"
                    v-if="data.item.status === 11 && userData.type === 1 || userData.type === 2 && data.item.isCompanyApproved === 0"
                    @click="confirmDisapproveInvoice(data.item.id)"
                  >
                    <feather-icon
                      :id="`decline-row-${data.item.id}-invoice-icon`"
                      class="cursor-pointer"
                      icon="XCircleIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Afkeuren
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`pdf-row-${data.item.id}-preview`"
                    @click="viewDocument(data.item)"
                  >
                    <feather-icon
                      :id="`pdf-row-${data.item.id}-preview-icon`"
                      class="cursor-pointer"
                      icon="EyeIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Bekijken
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`invoices-row-${data.item.id}-download`"
                    :href="`${axiosDefaultConfig.backendEndPoint}invoices/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoices-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                    />
                    Downloaden
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          title=""
        >
          <b-row>
            <b-col
              cols="6"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$router.go(-1)"
              >
                <span>terug</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BOverlay,
  BRow,
  BTable,
  BFormCheckbox, BDropdown, BDropdownItem, BBadge, BSpinner, BLink, BFormTextarea, BTooltip,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  formatDateTime,
  formatYearMothString,
  formatDate,
} from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {
  email,
  required,
  date,
  integer,
  between,
  requiredIf,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import freelancersStoreModule from '@/views/pages/freelancers/freelancersStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFreelancersList from '@/views/pages/freelancers/useFreelancersList'
import { heightTransition } from '@core/mixins/ui/transition'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    FormWizard,
    TabContent,
    flatPickr,
    BFormCheckbox,
    vSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    PdfViewer,
    BSpinner,
    BLink,
    BFormTextarea,
    BTooltip,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      date,
      integer,
      between,
      requiredIf,
      freelancerData: {
        link: {
          externalIdentifier: null,
          companyPaysFee: 0,
          blockFreelancerInvoiceCreate: 0,
          companyPaysPercentage: null,
        },
      },
      invoices: [],
      agreements: [],
      showOverlay: false,
      showPaySlipsOverlay: false,
      showAgreementOverlay: false,
      agreement: {
        name: null,
        type: 1,
        sectorID: null,
        freelancerID: null,
        companyID: null,
        startDate: null,
        endDate: null,
        isUndeterminedDuration: null,
        paymentTerm: 14,
        liabilityAmount: null,
        hasRelationshipClause: null,
        relationshipClauseDays: null,
        relationshipClauseOffenceFine: null,
        relationshipClauseDayFine: null,
        hasSecrecy: null,
        secrecyFine: null,
        hasSocialChargeNote: null,
        noticePeriod: null,
        hasCancellationCompensation: null,
        cancellationCompensationAmountFreelancer: null,
        cancellationCompensationAmountCompany: null,
        activities: [
          {
            description: null,
            amount: null,
            unit: null,
          },
        ],
      },
      startDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      endDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      showAddAGreementSpinner: false,
      paycheckPeriodSelected: null,
      showChangePaycheckPeriodSpinner: false,
      userData: {},
      disapproveInvoiceData: {
        status: null,
        id: null,
        denyReason: null,
      },
      showInvoiceDisapprovalSpinner: false,
      showAdd: false,
      showPercentage: false,
      paycheckPeriod: [],
    }
  },
  watch: {
    freelancerData: {
      deep: true,
      async handler(data) {
        if (data.link.companyPaysFee === 0) {
          this.showPercentage = false
        }
        if (data.link.companyPaysFee === 1) {
          this.showPercentage = true
        }
      },
    },
  },
  methods: {
    getPayPeriods() {
      store
        .dispatch('app-freelancer-edit/fetchPayPeriods')
        .then(response => {
          this.paycheckPeriod = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Verloningsperiode',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    async showAddModal() {
      this.showAdd = false
      await this.$refs.modalAgreement.show()
      this.showAdd = true
      setTimeout(() => this.$refs.createAgreementWizard.reset(), 5000)
    },
    confirmAgreementApproveText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Ja, overeenkomst accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-freelancer-edit/updateAgreement', { id, approve: 1 })
            .then(() => {
              this.fetchAgreements()
              this.$swal({
                icon: 'success',
                title: 'Overeenkomst',
                text: 'De overeenkomst is geaccepteerd.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Overeenkomst accepteren',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    confirmAgreementDisapproveText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, overeenkomst afwijzen!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-freelancer-edit/updateAgreement', { id, approve: -1 })
            .then(() => {
              this.fetchAgreements()
              this.$swal({
                icon: 'success',
                title: 'Overeenkomst',
                text: 'De overeenkomst is afgewezen.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Overeenkomst afwijzen',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: `${errorAndSuccesDefaultMessage.saveDataError}`,
                },
              })
            })
        }
      })
    },
    confirmDisapproveInvoice(id) {
      this.disapproveInvoiceData.id = id
      this.$refs.modalDisapproveInvoice.show()
    },
    handleDisapproveInvoice() {
      this.showInvoiceDisapprovalSpinner = true

      if (this.userData.type === 1) {
        this.disapproveInvoiceData.isFreelancerApproved = -1
        this.disapproveInvoiceData.status = 50
      }

      if (this.userData.type === 2) {
        this.disapproveInvoiceData.isCompanyApproved = -1
        this.disapproveInvoiceData.status = 51
      }

      store
        .dispatch('app-invoices/approveInvoice', this.disapproveInvoiceData)
        .then(() => {
          this.$refs.modalDisapproveInvoice.hide()
          this.showInvoiceDisapprovalSpinner = false
          this.refetchData()
        })
        .catch(() => {
          this.$refs.modalDisapproveInvoice.hide()
          this.showInvoiceDisapprovalSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur afwijzen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    confirmApproveInvoice(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, factuur accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.approveInvoice(id)
        }
      })
    },
    approveInvoice(id) {
      const acceptData = { id }

      if (this.userData.type === 1) {
        acceptData.isFreelancerApproved = 1
      }

      if (this.userData.type === 2) {
        acceptData.isCompanyApproved = 1
      }

      store
        .dispatch('app-invoices/approveInvoice', acceptData)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur accepteren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    showModalPaycheckPeriode(id) {
      this.id = id
      this.$refs.modalChangePaycheckPeriodInvoice.show()
    },
    handleChangePaycheckPeriode() {
      this.showChangePaycheckPeriodSpinner = true
      const period = this.paycheckPeriod.filter(b => b.key === this.paycheckPeriodSelected)[0]

      store
        .dispatch('app-freelancer-edit/patchInvoice', { paycheckPeriod: period.key, id: this.id })
        .then(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.refetchData()
        })
        .catch(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verloningsperiode wijzigen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloningsperiode. Probeer het nogmaals.',
            },
          })
        })
    },
    agreementFormSubmitted() {
      const userData = JSON.parse(localStorage.getItem('userData'))

      if (userData.type === 1) {
        this.agreement.freelancerID = userData.id
        this.agreement.companyID = router.currentRoute.params.id
      }

      if (userData.type === 2) {
        this.agreement.freelancerID = router.currentRoute.params.id
        this.agreement.companyID = userData.id
      }

      this.showAddAGreementSpinner = true
      store.dispatch('app-freelancer-edit/addAgreement', this.agreement)
        .then(() => {
          this.showAddAGreementSpinner = false
          this.$refs.modalAgreement.hide()
          this.fetchAgreements()
        })
        .catch(() => {
          this.showAddAGreementSpinner = false
          this.$refs.modalAgreement.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Overeenkomst toevoegen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanmaken van de overeenkomst. Probeer later het nog een keer.',
            },
          })
        })
    },
    validationAgreementStep1Form() {
      return new Promise((resolve, reject) => {
        this.$refs.agreementStep1.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAgreementStep2Form() {
      return new Promise((resolve, reject) => {
        this.$refs.agreementStep2.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAgreementStep3Form() {
      return new Promise((resolve, reject) => {
        this.$refs.agreementStep3.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationAgreementStep4Form() {
      return new Promise((resolve, reject) => {
        this.$refs.agreementStep4.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    repeateAgain() {
      this.agreement.activities.push({
        description: null,
        amount: null,
        unit: null,
      })
    },
    removeItem(index) {
      this.agreement.activities.splice(index, 1)
    },
    updateFreelancer() {
      store.dispatch('app-freelancer-edit/updateFreelancer', {
        id: this.freelancerData.id,
        companyPaysFee: this.freelancerData.link.companyPaysFee,
        externalIdentifier: this.freelancerData.link.externalIdentifier,
        companyPaysPercentage: this.freelancerData.link.companyPaysPercentage,
      })
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Freelancer informatie',
            text: 'Er is een fout opgetreden bij het aanpassen van de data. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    getFreelancer() {
      this.showOverlay = true
      store.dispatch('app-freelancer-edit/fetchFreelancer', { id: router.currentRoute.params.id })
        .then(response => {
          this.showOverlay = false
          this.freelancerData = response.data
        })
        .catch(() => {
          this.showOverlay = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen freelancer informatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    fetchInvoices() {
      this.showPaySlipsOverlay = true
      store
        .dispatch('app-freelancer-edit/fetchInvoices', { id: router.currentRoute.params.id }, {
          sortDesc: true,
        })
        .then(response => {
          this.showPaySlipsOverlay = false
          this.invoices = response.data.items
        })
        .catch(() => {
          this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    isUndeterminedDurationChecked(value) {
      if (value) {
        this.agreement.endDate = null
        this.$refs.agreementStep1.reset()
      }
    },
    fetchAgreements() {
      // this.showPaySlipsOverlay = true
      store
        .dispatch('app-freelancer-edit/fetchAgreements', router.currentRoute.params.id, {
          sortDesc: true,
        })
        .then(response => {
          // this.showPaySlipsOverlay = false
          this.agreements = response.data.items
        })
        .catch(() => {
          // this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}invoices/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
    viewAgreement(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}agreements/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
  },
  beforeMount() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.getPayPeriods()
    this.getFreelancer()
    this.fetchInvoices()
    this.fetchAgreements()
  },
  // mounted() {
  //   this.$root.$on('bv::modal::hide', () => {
  //     this.$refs.createAgreementWizard.navigateToTab(-1)
  //   })
  // },
  setup() {
    const FREELANCER_EDIT_APP_STORE_MODULE_NAME = 'app-freelancer-edit'

    // Register module
    if (!store.hasModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)) store.registerModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME, freelancersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(FREELANCER_EDIT_APP_STORE_MODULE_NAME)
    })

    const {
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      resolveTypeAgreementVariant,
    } = useFreelancersList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      formatYearMothString,
      formatDateTime,
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      axiosDefaultConfig,
      resolveTypeAgreementVariant,
      formatDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
